<template>
  <module
    ref="module"
    id="buyVps"
    titleIcon="fa fa-cube"
    :title="$t('vps')"
    :use-default-list="false"
  >
    <div slot="global">

      <!-- Modal for Confirm Removal -->
      <modal :show.sync="alert_me" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('suggestedtopup') }}</h4>

        <div class="col-12 text-center">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <template slot="footer">
          <p-button @click.prevent="topUp" link>{{ $t('topuptitle') }}</p-button>
        </template>
      </modal>
      <!-- End Modal -->

      <div class="row d-flex justify-content-center">
        <div class="col-md-10 mr-auto ml-auto">
            <div v-if="inprogress" class="text-center">
              <h2>{{ inprogresstxt }}</h2>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>

            <wizard :prevButtonText="prevBtnTxt" :nextButtonText="nextBtnTxt" :finishButtonText="finBtnTxt" v-else>
              <template slot="header">
                <h3 class="card-title">{{ $t('requestvps') }}</h3>
                <h3 class="description">{{ $t('whyvps') }}</h3>

                <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;" v-if="alert_me">
                  {{ alert_msg }}
                </div>

              </template>

              <wizard-tab :before-change="() => validateStep('step1')">
                <template slot="label">
                  <i class="fa fa-cube"></i>
                  {{ $t('vpsplans') }}
                </template>
                <choose-plan-step ref="step1" @on-validated="onStepValidated"></choose-plan-step>
              </wizard-tab>

              <wizard-tab :before-change="() => validateStep('step2')">
                <template slot="label" >
                  <i class="fa fa-globe"></i>
                  {{ $t('vpslocation') }}
                </template>
                <choose-location-step ref="step2" @on-validated="onStepValidated"></choose-location-step>
              </wizard-tab>

              <wizard-tab :before-change="() => validateStep('step3')" v-if="isadmin">
                <template slot="label" >
                  <i class="fa fa-user"></i>
                  {{ $t('vpsuser') }}
                </template>
                <choose-user-step ref="step3" @on-validated="onStepValidated"></choose-user-step>
              </wizard-tab>

            </wizard>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
  import ChoosePlanStep from './AddVPSWiz/GetPlanStep.vue'
  import ChooseLocationStep from './AddVPSWiz/GetLocationStep.vue'
  import ChooseUserStep from './AddVPSWiz/GetUserStep.vue'
  import PayInstruct from './AddVPSWiz/PaymentInstructions.vue'
  import Constants from 'src/assets/constants'

  import swal from 'sweetalert2'
  import {Wizard, WizardTab, Modal} from 'src/components/UIComponents'

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  export default {
    data() {
      return {
        wizardModel: {},
        prevBtnTxt: this.$t('previous'),
        nextBtnTxt: this.$t('next'),
        finBtnTxt: this.$t('create'),
        inprogresstxt: this.$t('creatingvps'),
        inprogress: false,
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        isadmin: false,
        walletapp: Constants.methods.walletme()
      }
    },
    components: {
      ChoosePlanStep,
      ChooseLocationStep,
      ChooseUserStep,
      Wizard,
      WizardTab,
      PayInstruct,
      Modal
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      topUp() {
        location.href = "/billing/funding";
      },
      vpsMain() {
        this.inprogress = false;
        location.href = "/vps/main";
      },
      vpsFunding() {
        this.inprogress = false;
        location.href = "/billing/funding";
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model};
        let proceed = true;
        const successop = resp => {
          if (!resp.success) {
            swal({
              title: this.$t("attentiontxt"),
              text: this.$t(resp.msg),
              type: 'warning',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(this.vpsFunding)
            return;
          }
          swal(this.$t("successtxt"), this.$t(resp.msg) , "success").then(this.vpsMain)
        };
        const failop = error => {
          // TODO: add msg
          this.inprogress = false;
          try {
            if (typeof error === 'string') {
              this.$toast.error(this.$t(error))
            } else if (error.data && typeof error.data === 'string') {
              this.$toast.error(this.$t(error.data))
            } else if (error.data.msg && typeof error.data.msg === 'string') {
              this.$toast.error(this.$t(error.data.msg))
            } else {
              this.$toast.error(this.$t('failcreatingvps'))
            }
          } catch (e) {
            this.$toast.error(this.$t('failcreatingvps'))
          }

        };
        if (this.isadmin && (this.wizardModel.user === undefined || this.wizardModel.user === null || this.wizardModel.user === '')) {
          proceed = false;
        }
        if (proceed && this.isadmin == false && this.wizardModel.paymentRead !== undefined && this.wizardModel.plan !== undefined && this.wizardModel.location !== undefined) {
          this.inprogress = true;
          // Proceed with creation of VPS
          this.$createVPS_v2(user_data, true, this.wizardModel)
            .then(successop, failop);
        }
        else {
          if (proceed && this.isadmin && this.wizardModel.plan !== undefined && this.wizardModel.location !== undefined) {
            this.inprogress = true;
            // Proceed with creation of VPS
            this.$createVPS_v2(user_data, true, this.wizardModel)
              .then(successop, failop);
          }
        }
      },
      wizardComplete() {
        swal('Good job!', 'You clicked the finish button!', 'success')
      }
    },
    mounted() {
      if (user_data.roles.indexOf('admin') >= 0) {
        this.isadmin = true;
      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    }
  }
</script>
<style lang="scss">
</style>
