<template>
  <div>
    <h5 class="info-text">{{ $t('choosevpsplan') }}</h5>

    <div class="row justify-content-center">
      <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;" v-if="alert_me">
        {{ alert_msg }}
      </div>

      <div class="col-lg-10">
        <div class="row">
          <div class="col-sm-4" v-for="plan in plans">
            <icon-checkbox-alt
              v-model="vpsplan[plan.title]"
              :icon="plan.icon"
              :cost="plan.cost"
              :title="plan.title"
              @input="planChosen">
            </icon-checkbox-alt>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import {IconCheckboxAlt} from 'src/components/UIComponents'

  export default {
    components: {
      IconCheckboxAlt
    },
    data() {
      return {
        plans: [],
        vpsplan: null,
        chosenplan: null,
        addons: [],
        model: {
          plan: null,
          addons: []
        },
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger'
      }
    },
    methods: {
      loadPlans(resp) {
        if (!resp.success) {
          return;
        }
        let data = resp.data;
        let veepplans = {};
        this.plans = [];
        for (var i=0;i < data.length;i++) {
          let plan = data[i];
          let plan_icon = "fa fa-cube";
          this.plans.push({
            icon: plan_icon,
            title: plan['name'],
            cost: "$"+ plan['cost'],
            rawcost: plan['cost']
          });
          veepplans[plan['name']] = false;
        }
        this.vpsplan = Object.assign({}, veepplans);
      },
      loadAddons(resp) {
        if (!resp.success) {
          return;
        }
        this.addons = resp.data;
      },
      toggleAddon(check, product) {
        if (check) {
          this.model.addons.push(product);
        } else {
          this.model.addons = this.model.addons.filter(addon => addon != product)
        }
      },
      planChosen(e) {
        var keys = Object.keys(this.vpsplan);
        for(var i=0;i < keys.length;i++) {
          var k = keys[i];
          if (this.vpsplan[k]) {
            if (this.chosenplan === null) {
              this.chosenplan = k;
              break;
            }
            else {
              if (this.chosenplan !== k) {
                this.vpsplan[this.chosenplan] = false;
                this.chosenplan = k;
              }
            }
          }
        }
        // Finalize the Plan chosen
        if (this.chosenplan !== null) {
          var planidx = this.plans.findIndex(p => this.chosenplan == p.title);
          if (planidx >= 0) {
            this.model.plan = this.plans[planidx];
          }
        }
        //console.log(this.chosenplan);
        this.alert_me = false;
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          if (this.model.plan === null) {
            this.alert_me = true;
            this.alert_msg = this.$t('mustchoosevpsplan');
            res = false;
          } else {
            this.alert_me = false;
          }
          this.$emit('on-validated', res, this.model);
          return res
        })
      }
    },
    mounted() {
      const failop = error => {
        console.log(error);
      };
      // Get VPSes for user
      this.$getVPSPlans_v2({type:'VPS'})
        .then(this.loadPlans, failop);

      this.$getVPSPlans_v2({type:'Addons'})
        .then(this.loadAddons, failop);
    }
  }
</script>
<style lang="scss">
  .choice.active .icon {
    background-color: #2ca8ff;
  }

  .choice.active .icon i {
    color: #fff;
  }
</style>
